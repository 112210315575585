
import { Vue, prop } from "vue-class-component";

class Props {
  text = prop<string>({
    default: "",
    type: String
  });
  textColor = prop<string>({
    default: "black-lp-300",
    type: String
  });
  fontSize = prop<number>({
    default: 14,
    type: Number
  });
  fontWeight = prop<string>({
    default: "normal",
    type: String
  });
  description = prop<string>({
    default: "",
    type: String
  });
  icon = prop<string>({
    default: "",
    type: String
  });
  iconColor = prop<string>({
    default: "",
    type: String
  });
  width = prop<string>({
    default: "220",
    type: String
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  widthIcon = prop<string>({
    default: "w-5",
    type: String
  });
  adjustedPosition = prop<string>({
    default: "",
    type: String
  });
}
export default class TooltipV2 extends Vue.with(Props) {
  // tooltip
  isTooltip = false;
  toggleTooltipHeader(event: any) {
    if (!this.disabled) {
      this.isTooltip = !this.isTooltip;
      const refs: any = this.$refs;
      refs["opTooltip"].toggle(event);
    }
  }
  hide() {
    const refs: any = this.$refs;
    refs["opTooltip"].hide();
  }
}
